// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-politika-js": () => import("../src/pages/cookies-politika.js" /* webpackChunkName: "component---src-pages-cookies-politika-js" */),
  "component---src-pages-galerija-js": () => import("../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nama-js": () => import("../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-pravila-privatnosti-js": () => import("../src/pages/pravila-privatnosti.js" /* webpackChunkName: "component---src-pages-pravila-privatnosti-js" */),
  "component---src-pages-usluge-js": () => import("../src/pages/usluge.js" /* webpackChunkName: "component---src-pages-usluge-js" */)
}

