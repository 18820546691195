module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Monolit - visinski radovi","short_name":"Monolit d.o.o.","description":"Tvrtka posluje u domeni graditeljstva na visini i specijalnih radova industrijskog alpinizma.","start_url":"/","background_color":"#ffffff","theme_color":"#d01d16","display":"standalone","icon":"src/images/favicon.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
